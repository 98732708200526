import { create } from '@storybook/theming/create'

export default create({
  base: 'light',
  brandTitle: 'Code Gaia Storybook',
  brandUrl: 'https://codegaia.io',
  brandImage: 'https://codegaia.io/wp-content/uploads/2022/08/code-gaia-logo-bl.svg',
  brandTarget: '_self',
  booleanBg: 'white',
  booleanSelectedBg: '#1F4DA0',
  buttonBg: 'white',
  buttonBorder: '#1F4DA0',
})
